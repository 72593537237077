import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "30",
  height: "32",
  viewBox: "0 0 30 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"#212529\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M29.3996 4.07053L29.3996 31.6084H0.599609L0.599611 4.07053L29.3996 4.07053ZM3.67836 28.5505H26.1604V10.4705H3.67836V28.5505Z\"></path><path fill=\"#FF7602\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.59008 7.17452L6.59008 0.391571L9.71289 0.391571L9.71289 7.17452L6.59008 7.17452Z\"></path><path fill=\"#FF7602\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.2854 7.17452L20.2854 0.391571L23.4082 0.391571L23.4082 7.17452L20.2854 7.17452Z\"></path><path fill=\"#212529\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.113 21.0079H6.75391V18.2435H11.113V21.0079Z\"></path><path fill=\"#212529\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.1794 21.0079H12.8203V18.2435H17.1794V21.0079Z\"></path><path fill=\"#212529\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.1794 16.4349H12.8203V13.6705H17.1794V16.4349Z\"></path><path fill=\"#212529\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M23.2478 21.0079H18.8887V18.2435H23.2478V21.0079Z\"></path><path fill=\"#212529\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M23.2478 16.4349H18.8887V13.6705H23.2478V16.4349Z\"></path><path fill=\"#212529\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.113 25.9928H6.75391V23.2284H11.113V25.9928Z\"></path><path fill=\"#212529\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.1794 25.9928H12.8203V23.2284H17.1794V25.9928Z\"></path><path fill=\"#212529\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M23.2478 25.9928H18.8887V23.2284H23.2478V25.9928Z\"></path>", 11)
  ])))
}
export default { render: render }